import CourseModel from "./CourseModel";

const courseList = [
  new CourseModel({id:'ENCE 621', name: 'Uncertainty Modeling and Analysis'}),
  new CourseModel({id:'ENCE 620', name: 'Risk Analysis in Engineering and Economics'}),
  // new CourseModel({id:'ENCE 615', name: 'Structural Reliability'}),
  // new CourseModel({id:'ENCE 353', name: 'Risk Assessment and Decision Analysis for Project Management'}),
  new CourseModel({id:'ENCE 302', name: 'Probability and Statistics for Civil and Environmental Engineers'}),
];

export default courseList;