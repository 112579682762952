const archivedPaperList = [
"Lombardo, F. and Ayyub, B., 2017. “An Approach to Estimating Near-Surface Extreme Wind Speeds with Climate Change Considerations.” ASCE-ASME J. Risk Uncertainty Eng. Syst., Part A: Civ. Eng., http://dx.doi.org/10.1061/AJRUA6.0000909. ",
"Huang, H., Zhang, D.M., and Ayyub, B. M., 2017. “An Integrated Risk Sensing System for Geo-structural Safety,” Journal of Rock Mechanics and Geotechnical Engineering, 1-13, http://dx.doi.org/10.1016/j.jrmge.2016.09.005. ",
"Shao, J., Pantelous, A., Ayyub, B. M., Chan, S., and Nadarajah, S., 2017. “Nuclear Catastrophe Risk Bonds in a Markov Dependent Environment,” ASCE-ASME J. Risk Uncertainty Eng. Syst., Part A: Civil Eng., https://doi.org/10.1061/AJRUA6.0000923. ",
"Gierson, M., Phillips, B., Duthinh, D., and Ayyub, B. M., 2017. “Wind-Pressure Coefficients on Low-Rise Building Enclosures Using Modern Wind-Tunnel Data and Voronoi Diagrams,” ASCE-ASME J. Risk Uncertainty Eng. Syst., Part A: Civil Eng., ISSN 2376-7642, https://doi.org/10.1061/AJRUA6.0000915. ",
"Huang, H., Zhang, Y.J., Zhang, D.M., and Ayyub, B. M., 2017. “Field data-based Probabilistic Assessment on Degradation of Deformational Performance for Shield Tunnel in Soft Clay,” Journal of Tunneling and Underground Space Technology, 67 (2017) 107–119, http://dx.doi.org/10.1016/j.tust.2017.05.005. ",
"Zhang, F., Du, F., Huang, H., Zhang, D., Ayyub, B. M., and Beer, M., 2018. “Resiliency Assessment of Urban Rail Transit Networks: Shanghai Metro as an Example,” Safety Science, Elsevier, Volume 106, July 2018, Pages 230–243, https://doi.org/10.1016/j.ssci.2018.03.023. ",
"Hu, H., and Ayyub, B. M., 2018. “Extreme Precipitation Analysis and Prediction for a Changing Climate,” ASCE-ASME Journal of Risk and Uncertainty in Engineering Systems, Part A: Civil Engineering, Vol. 4, Issue 3 (September 2018) https://doi.org/10.1061/AJRUA6.0000980.",
"Zhang, Y., and Ayyub, B. M., 2018. “Urban Heat Projections in a Changing Climate: Washington D.C. as a Case Study,” ASCE-ASME J. Risk Uncertainty Eng. Syst., Part A: Civ. Eng., 4(4), https://doi.org/10.1061/AJRUA6.0000985.",
"Emanuel, R., and Ayyub, B. M., 2019. “Assessing Resilience Model Responsiveness in the Context of Stakeholder Preferences in Decision Support Systems,” ASCE-ASME J. Risk Uncertainty Eng. Syst., Part A: Civ. Eng., ISSN: 2376-7642, DOI: 10.1061/AJRUA6.0001002.",
"Mishra, S., and Ayyub, B. M., 2018, “Predicting Regional Economic Activity using Artificial Intelligence (AI) Methods: Case Study with Indian States,” IASSI Quarterly, Indian Association of Social Science Institutions, Contributions to Indian Social Science, Vol. 37, Nos. 3 & 4, July-December 2018, http://www.iassi.org/IASSI_quarterly.html. ",
"Saadat, Y., Ayyub, B. M., Zhang, Y. J., Zhang, D. M., and Huang, H. W. 2019. “Resilience of Metrorail Networks: Quantification with Washington D.C. as a Case Study,” ASCE-ASME J. Risk Uncertainty Eng. Syst., Part B: Civ. Eng., doi:10.1115/1.4044038",
"Ayyub, B. M., Zhang, Y. J., Zhang, D. M., Huang, H. W., 2018 (accepted). “Towards Resilience of Urban Tunnels in a Changing Climate: Washington D.C. and Shanghai as Case Studies,” Risk Analysis, Wiley.",
"Hu, H., and Ayyub, B. M., 2019, “Validating and Enhancing Extreme Precipitation Projections by Downscaled Global Climate Model Results and Copula Methods,” Journal of Hydrologic Engineering, 24(7), ASCE, DOI: 10.1061/(ASCE)HE.1943-5584.0001803 ",
"Zhang, Y., and Ayyub, B. M., 2019 (Submitted). “Projecting Heat Waves Temporally and Spatially for Local Adaptations in a Changing Climate: Washington D.C. as a Case Study,” Climate Change.",
"Mishra, S., and Ayyub, B. M., 2019 “Entropy for Quantifying Uncertainty and Risk in Economic Disparity,” Risk Analysis, Wiley International Journal, DOI: 10.1111/risa.13313. ",
"Mishra, S., and Ayyub, B. M., 2019 (Submitted) “Ensemble Deep Learning Framework for Financial Time Series Predictions using Dynamic Time Warping (DTW) based Hierarchical Clustering Analysis (HCA) and Long Short Term Memory (LSTM) with Uncertainty Quantification (UQ) Expert Systems With Applications,” Expert Systems With Applications, Elsevier.",
"Zhang, Y. J., Ayyub, B. M., Zhang, D. M., Saadat, Y., Huang, H. W., 2018 (Submitted). “Vulnerability Assessment of a Double-Weighted Metrorail Transit Network: Shanghai Metro as an Example,” J. of Infrastructure Systems, ASCE.",
"Emanuel, R., Ayyub, B. M., and Donohue, B. 2018 (Submitted). “A Hybrid Methodology for Infrastructure Resilience Measurement: the Impact of Electric Power Distribution Failures using the City of Austin and post-Maria Puerto Rico as Case Studies,” Risk Analysis J., Wiley.",
"Duan, S. and Ayyub, B. M. 2019 (accepted). “Network Resilience for Cyber-Human-Physical Systems,” Review Paper, ASCE-ASME J. Risk Uncertainty Eng. Syst., Part A: Civ. Eng.",
"Mishra, S. and Ayyub, B. M. 2019 (Submitted). “Entropy-Based Economic Disparity Profiles: The Tale of Four US Cities Baltimore, Detroit, San Francisco, and Washington, DC,” Journal of Regional Science, Wiley.",
"Saadat, Y., Ayyub, B. M., Zhang, Y., Zhang, D., and Huang, H., 2019 (submitted), “Vulnerability Assessment of a Weighted Metro Network Incorporating Ridership,” Journal of Transportation Engineering, Part A: Systems, ASCE.",
"Emanuel, R., and Ayyub, B. M., 2019 (submitted), “A Hybrid Resilience Framework to Apply Stakeholder Preferences to Aircraft Fleets,” APL Technical Digest, Applied Physics Laboratory (AL), The Johns Hopkins University.",
"Mishra, S., and Ayyub, B. M., 2019, (submitted) “Artificial Intelligence (AI) to Inform Executive Decisions for Mega Projects,” Economic & Political Weekly, https://www.epw.in/. ",
"Mishra, S., and Ayyub, B. M., 2019, (submitted) “Artificial Intelligence (AI) to Inform Executive Decisions for Mega Projects,” Project Management Journal.",
"Moss, Richard H.; Susan Avery; Kristin Baja; Maxine Burkett; Ann Marie Chischilly; Jan Dell; Paul Fleming; Kerrie Geil; Katharine Jacobs; Andrew Jones; Kim Knowlton; Jay Koh; Maria Lemos; Jerry Melillo; Raj Pandya; Therese Richmond; Lynn Scarlett; Jared Snyder; Missy Stults; Anne Waple; Jessica Whitehead; Daniel Zarrilli; Bilal Ayyub; James Fox; Auroop Ganguly; Lucas Joppa; Susan Julius; Paul Kirshen; Rebecca Kreutter; Amy McGovern; Ryan Meyer; James Neumann; William Solecki; Joel Smith; Philippe Tissot; Gary Yohe; Rae Zimmerman, 2019 (submitted), “A Framework for Sustained Climate Assessment in the United States,” Weather, Climate and Society, American Meteorological Society.",
"Hu, H., and Ayyub, B. M., 2019 (accepted) “Machine Learning for Projecting Extreme Precipitation Intensity of Short Periods,” Geosciences, Open Access Special Issue by MPDI, 9(5), 209; https://doi.org/10.3390/geosciences9050209. ",
"Huang, H., Zhang, D.M., Zhang, Y.J., Ayyub, B. M., and Wang, F., 2019. (submitted) “Characterization of Deformational Resilience of Segmental Joint for Shield Tunnel Lining based on Full-Scale Model Tests,” Journal of Tunneling and Underground Space Technology, Elsevier.",
"Davis, C. A., Ayyub, B. M., McNeil, S., Kobayashi, K., Tatano, H., Onishi, M., Takahashi, Y., Honda, R., and Koike, T., 2019, (in preparation) “Infrastructure System Resilience Framework for Assessment, Governance and Management, ASCE-ASME J., TBD.",
"Moss, R. H., Ayyub, B. M., Glackin, M., Hill, A., Jacobs, K. L., Melillo, J., Richmond, T. C., Scarlett, L., and Zarrilli, D., 2019, “Bridging the Gap with the Science for Climate Action Network,” EOS as the leading source for about the Earth and space sciences and their impact by American Geophysical Union (AGU), April 2019. https://eos.org/project-updates/bridging-the-gap-with-the-science-for-climate-action-network. ",
"Saadat, Y., Ayyub, B. M., Zhang, Y., Zhang, D., and Huang, H., 2019 (submitted), “Resilience-based Strategies for Topology Enhancement and Recovery of Metrorail Transit Networks,” ASCE-ASME J. Risk Uncertainty Eng. Syst., Part A: Civ. Eng., ASCE.",
"Hill, A. C., Mason, D. J., Potter, J. R., Hellmuth, M., Ayyub, B. M., Baker, J. W., “Ready for Tomorrow: Seven Strategies for Climate Resilient Infrastructure,” A Hoover Institution Essay, Stanford University, The Johnson Center, Washington D.C. ",
"Zhang, Y.J., Huang, H., Zhang, D.M., and Ayyub, B. M., 2019. (submitted) “Deformation Recoverability of Longitudinal Joints in Segmental Tunnel Linings: Experimental and Analytical Study,” Journal of Tunneling and Underground Space Technology, Elsevier.",
"Zhang, D.M., Du, F., Huang, H., Zhang, F., Ayyub, B. M., and Beer, M., 2017 (Submitted). “Resiliency Assessment Model of Urban Rail Transit Networks: Shanghai Metro as an Example,” Safety Science, Elsevier.",
"Hu, H., and Ayyub, B. M., 2017 (Submitted). “Extreme Precipitation Analysis and Prediction for a Changing Climate,” ASCE-ASME J. Risk Uncertainty Eng. Syst., Part A: Civ. Eng.",
"Huang, H., Zhang, Y.J., Zhang, D.M., and Ayyub, B. M., 2016 (Submitted). “Field data-based Probabilistic Assessment on Degradation of Deformational Performance for Shield Tunnel in Soft Clay,” Journal of Tunneling and Underground Space Technology.",
"Huang, H., Zhang, D.M., and Ayyub, B. M., 2016 (accepted). “An Integrated Risk Sensing System for Geostructural Safety,” Journal of Rock Mechanics and Geotechnical Engineering.",
"Ayyub, B. M., and Wright, R. N., 2016. “Adaptive Climate Risk Control of Sustainability and Resilience for Infrastructure Systems,” Editorial, J Geography and Natural Disasters, 6(2), http://dx.doi.org/10.4172/2167-0587.1000e118. View",
"Lombardo, F. and Ayyub, B., 2016 (2017 accepted). “An Approach to Estimating Near-Surface Extreme Wind Speeds with Climate Change Considerations.” ASCE-ASME J. Risk Uncertainty Eng. Syst., Part A: Civ. Eng.",
"Webb, D., and Ayyub, B.M., 2016, “A Probabilistic Framework and Metrics for Sustainable Construction,” ASCE-ASME J. Risk Uncertainty Eng. Syst., Part A: Civil Eng., DOI: DOI: http://dx.doi.org/10.1061/AJRUA6.0000894.",
"Webb, D., and Ayyub, B.M., 2016, “Sustainable Construction and Manufacturing: Definitions, Metrics and Valuations for Decision Making,” ASCE-ASME J. Risk Uncertainty Eng. Syst., Part A: Civil Eng., DOI: http://dx.doi.org/10.1061/AJRUA6.0000893.",
"Ayyub, B. M., Pantelous, A., and Shao, J., 2016. “Towards Resilience to Nuclear Accidents: Financing Nuclear Liabilities via Catastrophe Risk Bonds” ASCE-ASME J. Risk Uncertainty Eng. Syst., Part B: Mechanical Eng., DOI: 10.1115/1.4033518. View",
"Koko, T., Ayyub, B.M., and Gallant, K., 2016, “Simulation of Electric-Current Induced Drowning Accident Scenarios for Boating Safety,” ASCE-ASME J. Risk Uncertainty Eng. Syst., Part B: Mechanical Eng., DOI: http://dx.doi.org/10.1115/1.4032262.",
"Ayyub, B.M., Koko, T., Blair, A. N., and Akpan, U. O., 2016. “Risk Assessment Methodology for Electric-Current Induced Drowning Accidents,” ASCE-ASME J. Risk Uncertainty Eng. Syst., Part B: Mechanical Eng., DOI: http://dx.doi.org/10.1115/1.4032308.",
"Gilbert, S., and Ayyub, B., 2016. “Models for the Economics of Resilience,” ASCE-ASME J. Risk Uncertainty Eng. Syst., Part A: Civil Eng., DOI: 10.1061/AJRUA6.0000867. View",
"Akpan, U., Ayyub, B.M., Koko, T. and Rushton, P., 2015. “Development of Reliability-Based Damage-Tolerant Optimal Design of Ship Structures,” ASCE-ASME J. Risk Uncertainty Eng. Syst., Part A: Civil Eng., DOI: 10.1061/AJRUA6.0000836. View",
"Akpan, U., Koko, T., Ayyub, B.M., and Dunbar, T., 2015. “Reliability-Based Optimal Design of Steel Box Structures Part II: Ship Structure Applications,” ASCE-ASME J. Risk Uncertainty Eng. Syst., Part A: Civil Eng., DOI: 10.1061/AJRUA6.0000830. View",
"Ayyub, B. M., Akpan, U. O., Koko, T. and Dunbar, T., 2015. “Reliability-Based Optimal Design of Steel Box Structures Part I: Theory” ASCE-ASME J. Risk Uncertainty Eng. Syst., Part A: Civil Eng., DOI: 10.1061/AJRUA6.0000829.",
"Ayyub, B., 2015. “Practical Resilience Metrics for Planning, Design and Decision Making” ASCE-ASME J. Risk Uncertainty Eng. Syst., Part A: Civil Eng., DOI: 10.1061/AJRUA6.0000826. View",
"Ayyub, B. M., and Wehr, S. (2015) “Risk Models for Evaluation and Type Classification of Personal Flotation Devices,” ASCE-ASME J. Risk Uncertainty Eng. Syst., Part B: Mechanical Eng., Vol. 1, DOI: 10.1115/1.4026399. View",
"Ayyub, B. M., Stambaugh, K. A., McAllister, T. A., de Souza, G. F., and, D., 2015 “Structural Life Expectancy of Marine Vessels: Ultimate Strength, Corrosion, Fatigue, Fracture and Systems,” ASCE-ASME J. Risk Uncertainty Eng. Syst., Part B: Mechanical Eng., Vol 1, DOI: 10.1115/1.4026396. View",
"Ayyub, B., 2015. Editorial: Editor’s Introduction to the Aims and Scope of the Journal.” ASCE-ASME J. Risk Uncertainty Eng. Syst., Part B: Mechanical Eng., Vol. 1. (same as Ayyub 2014)",
"Lombardo, F. and Ayyub, B., 2015. “Analysis of Washington, DC, Wind and Temperature Extremes with Examination of Climate Change for Engineering Applications.” ASCE-ASME J. Risk Uncertainty Eng. Syst., Part A: Civ. Eng., 10.1061/AJRUA6.0000812, 04014005. View",
"Ayyub, B., 2014. “Introduction to the Aims and Scope of the Journal.” ASCE-ASME J. Risk Uncertainty Eng. Syst., Part A: Civ. Eng., 10.1061/AJRUA6.0000001, 01614001. (same as Ayyub 2015) View",
"Wright, R. N., Ayyub, B. M., and Lombardo, F. T., “Bridging the Gap between Climate Change Science and Structural Engineering Practice,” Structure Magazine of the National Council of Structural Engineers Associations (NCSEA), the Structural Engineering Institute (SEI) of ASCE, and the Council of American Structural Engineers (CASE) of the American Council of Engineering Companies (ACEC), 2013, 29-31.",
"Ayyub, B. M., “Systems Resilience for Multi-Hazard Environments: Definition, Metrics and Valuation for Decision Making,” Risk Analysis J., 34(2):340-55, DOI: 10.1111/risa.12093, 2014 (Epub 2013 Jul 22). View",
"Kamal, H., Ayyub, B., and Abdullah, W. (2013). ”Land Subsidence in Arid Terrain: Methodology toward Risk Analysis.” Nat. Hazards Rev., 14(4), 268–280. http://dx.doi.org/10.1061/(ASCE)NH.1527-6996.0000099",
"Ayyub, B. M., and Parker, L. “Financing Nuclear Liability,” Science, 334, 16 December 2011, 1494. View",
"Ayyub, B. M., Braileanu, H. G., and Qureshi, N., “Prediction and Impact of Sea Level Rise on Properties and Infrastructure of Washington, DC,” Risk Analysis Journal, Society for Risk Analysis, 2011 Oct 28, 1-18. doi: 10.1111/j.1539-6924.2011.01710.x. Picked up by ~300 media channels including CNN, Wall Street Journal, Washington Post, etc. View",
"Ayyub, B. M., and Kearney, M. S., “Towards the Development of Regional Risk Profiles and Adaptation Measures for Sea Level Rise,” Journal of Risk Analysis and Crisis Response, ISSN (print): 2210-8491/ ISSN (on-line): 2210-8505, July, 2011, 1(1), 75 – 89.",
"Shamoo, A. E., and Ayyub, B. M., “Benefit/Risk Estimates in Clinical Trials,” Drug Information Journal, Drug Information Association, 2011, 45, 1-7, 0092-8615/2011. View",
"Gu, J., Vichare, N., Ayyub, B. M., Pecht, M., “Application of Grey Prediction Model for Failure Prognostics of Electronics,” International Journal of Performability Engineering, 6(5), September 2010, 435-442.",
"Ayyub, B. M., Prassinos, P. G., and Etherton, J., “Risk-Informed Decision Making: Focus on Risk Management,” Mechanical Engineering Magazine, January 2010.",
"Ayyub, B. M., “On Uncertainty in Information and Ignorance in Knowledge,” J. General Systems, 39(4), May 2010, 415-435. View",
"Avrithi, K., and Ayyub, B. M., “A Reliability-Based Expression of ASME B&PV Code Eq. (11) for Class 2 and 3 Nuclear Pipes,” J. Pressure Vessel Technology, Technical Brief, ASME, 132, October 2010, 1.1-4.",
"Avrithi, K., and Ayyub, B. M., “A Reliability-Based Approach for Low-Cycle Fatigue Design of Class 2 and 3 Nuclear Piping,” Journal of Pressure Vessel Technology, ASME, 132, June 2010, 1.1-6.",
"Avrithi, K., and Ayyub, B. M., “Load and Resistance Factor Design LRFD of Nuclear Straight Pipes for Loads That Cause Primary Stress,” J. Pressure Vessel Technology, ASME, 132, April 2010, 1.1-14.",
"Kamal, H. A., Ayyub, B. M., and Abdullah, W. A., “A Risk Analysis Methodology for Land Subsidence,” Int. Journal of Engineering under Uncertainty: Hazard, Assessment, and Mitigation, 1(1-2), 2009, 35-46.",
"McGill, W., and Ayyub, B. M., “Regional Capabilities Performance Assessment for Homeland Security,” Systems Journal, IEEE, Volume 3, Issue 2, June 2009, 192 – 198, OID: 10.1109/JSYST.2009.2017398.",
"Ayyub, B. M., McGill, W. L., Foster, J., Jones, H. W., “Risk Analysis of a Protected Hurricane-Prone Region II: Computations and Illustrations,” ASCE Natural Hazards Review, May 2009, 54-67, DOI: 10.1061/(ASCE)1527-6988(2009)10:2(38). View",
"Ayyub, B. M., Foster, J., McGill, W. L., “Risk Analysis of a Protected Hurricane-Prone Region I: Model Development,” ASCE Natural Hazards Review, May 2009, 38-53, DOI: 10.1061/(ASCE)1527-6988(2009)10:2(38). View",
"McGill, W. L., and Ayyub, B. M., “Multicriteria Security System Performance Assessment Using Fuzzy Logic,” Journal of Defense Modeling and Simulation, Manuscript ID JDMS-2007-10-0024.R3, 2009, Vol. 4, No. 4, 356-376 (2007), DOI: 10.1177/154851290700400405.",
"Avrithi, K., and Ayyub, B. M., “A Reliability-Based Approach for the Design of Nuclear Piping for Internal Pressure,” Journal of Pressure Vessel Technology, ASME, August 2009, Vol.131, Issue 4, DOI: 10.1115/1.3122017.",
"Avrithi, K., and Ayyub, B. M., “Strength Model Uncertainties of Burst, Yielding, and Excessive Bending of Piping,” Journal of Pressure Vessel Technology, ASME, June 2009, Vol.131, Issue 3, DOI: 10.1115/1.3109983.",
"McGill, W. L., Ayyub, B. M., Kaminskiy, M., “A Quantitative Asset-Level Risk Assessment and Management Framework for Critical Asset Protection,” Risk Analysis International Journal, Society for Risk Analysis, Vol. 27, No. 5, 2007, 1265-1281, DOI: 10.1111/j.1539-6924.2007.00955.x.",
"Ayyub, B. M., McGill, W. L., Kaminskiy, M., “Critical Asset and Portfolio Risk Analysis for Homeland Security: An All-Hazards Framework,” Risk Analysis International Journal, Society for Risk Analysis, Vol. 27, No. 3, 2007, 789-801, DOI: 10.1111/j.1539-6924.2007.00911.x. View",
"McGill, W. L., and Ayyub, B. M., “Estimating parameter distributions in structural reliability assessment using the Transferable Belief Model,” Computers and Structures, 86, May 2008, 1052–1060, doi:10.1016/j.compstruc.2007.05.038.",
"Kakhi, M., Ayyub, B.M., Wokovich, A.M., Prodduturi, S., Doub, W.H., Buhse, L.F., Hussain, A.S., and Sadrieh, N., “Risk Analysis of Transdermal Drug Delivery Systems (TDDSs),” Poster Session, FDA Science Forum, Washington Convention Center, April 18-20, 2006, http://www.accessdata.fda.gov/.",
"Kaminskiy, M., and Ayyub, B. M., “Terrorist Population Dynamics Model,” Risk Analysis Journal, Society for Risk Analysis, June 2006, 26(3), 747-752.",
"Ayyub, B. M., Kaminskiy, M., Alman, P. R., Engle, A. Campbell, B. L., Thomas, III, W. L., “Assessing the Probability of the Dynamic Capsizing of Vessels,” J. of Ship Research, SNAME, December 2006, 50(4), 289-310.",
"2001-2005",
"Assakkaf, I., and Ayyub, B. M., “Comparative and Uncertainty Assessment of Design Criteria for Stiffened Panels,” Journal of Ship Research, SNAME, September 2004, 48(3), 231-247. View",
"Ayyub, B. M., “From Dissecting Ignorance to Solving Algebraic Problems,” J. Reliability Engineering and System Safety, July to September 2004, 85(1 to 3), 223-238.View",
"Akpan, U. O., Koko, T. S., Ayyub, B. M., and Dunbar, T. E., “Reliability Assessment of Corroding Ship Hull Structure,” NEJ, ASNE, 115(4), Fall 2003, 37-48. Winner of the “Jimmie” Hamilton Best Paper Award, 2003, ASNE",
"Ayyub, B.M., and Popescu, C., “Risk-based Expenditure Allocation for Infrastructure Improvement,“ Journal of Bridge Engineering, ASCE, 8(6), 2003, 394-404.",
"Ayyub, B. M., “Elicitation of expert opinions for uncertainty and risks: Answer to the Book Review by Roger M. Cooke,” Fuzzy Sets and Systems Volume 133, Issue 2 16 January 2003, 269-271. View",
"Akpan, U. O., Koko, T. S., Ayyub, B. M., and Dunbar, T. E., “Risk Assessment of Aging Ship Hull Structures in the Presence of Corrosion and Fatigue,” Marine Structures, 15(3), 2002, 211-232. View",
"Ayyub, B. M., Beach, J. E., Sarkani, S., and Assakkaf, I., “Risk Analysis and Management for Marine Systems,” NEJ, ASNE, 114(2), 2002, 181-206.",
"Sikora, J. P., Michaelson, R., and Ayyub, B. M., “Assessment of Cumulative Lifetime Seaway Loads for Ships,” Naval Engineers Journal, ASNE, 114(2), 2002, 167-180. View",
"Hess, III, P. E., Bruchman, D. D., Assakkaf, I. A., and Ayyub, B. M., “Uncertainties in Material Strength, Geometric and Load Variables,” NEJ, ASNE, 114(2), 2002, 139-165. View",
"Ayyub, B. M., Assakkaf, I., Kihl, D. P., and Sieve, M. W., “Reliability-Based Design Guidelines for Fatigue of Ship Structures,” Naval Engineers Journal, ASNE, 114(2), 2002, 113-138. Winner of the “Jimmie” Hamilton Best Paper Award, 2002, ASNE",
"Assakkaf, I., Ayyub, B. M., Hess, P., and Atua, K., “Reliability-Based Load and Resistance Factor Design (LRFD) Guidelines for Stiffened Panels and Grillages of Ship Structures,” Naval Engineers Journal, ASNE, 114(2), 2002, 89-111. View",
"Assakkaf, I., Ayyub, B. M., Hess, III, P. E., and Knight, D. E., “Reliability-Based Load and Resistance Factor Design (LRFD) Guidelines for Unstiffened Panels of Ship Structures,” Naval Engineers Journal, ASNE, 114(2), 2002, 69-88. View",
"Ayyub, B. M., Assakkaf, I., Sikora, J., Adamchack, J., Atua, K., Melton, W., and Hess, III, P. E., “Reliability-Based Load and Resistance Factor Design (LRFD) Guidelines for Hull Girder Bending,” Naval Engineers Journal, ASNE, 114(2), 2002, 43-68.",
"Ayyub, B. M., Assakkaf, I., Beach, J. E., Melton, W., and Nappi, Jr., N., Conley, J., “Methodology for Developing Reliability-Based Load and Resistance Factor Design (LRFD) Guidelines for Ship Structures,” Naval Engineers Journal, ASNE, 114(2), 2002, 23-41.",
"Ayyub, B. M., Beach, J. E., Packard, W. T., Melton, W., and Nappi, Jr., N., Preface to the Special Issue of the Naval Engineers Journal on Reliability-Based Load and Resistance Factor Design (LRFD) Guidelines for Ship Structures,“ Naval Engineers Journal, ASNE, 114(2), 2002, 21-22.",
"Ziara, M., Nigim, K., Enshassi, A., and Ayyub, B.M., “Strategic Implementation of Urban Infrastructure Priority Projects in Palestine,” J. of Infrastructure Systems, ASCE, 8(1), 2002, 2-11. View",
"Eloseily, K. H., Ayyub, B. M., and Patev, R. C., “Reliability Assessment of Pile Groups in Sand,” Journal of Structural Engineering, ASCE, 2002, 128(10), 1-8. View",
"Chang, Y. H., and Ayyub, B.M., “Hybrid Fuzzy Least-Square Regression Analysis and Its Reliability Measures,” Fuzzy Sets and Systems, 119, 2001, 225-246.",
"Chang, Y. H., and Ayyub, B.M., “Fuzzy Linear Regression Methods: A Comparative Assessment,” Fuzzy Sets and Systems, 119, 187-203, 2001. View",
"Blair, A. N., Ayyub, B. M., and Bender, W. J., “Fuzzy Stochastic Risk-Based Decision Analysis with the Mobile Offshore Base as a Case Study,” Int. Journal of Marine Structures, 14 (1&2), Jan. 2001, 69-88.View",
"Bender, W. J., Blair, A. N., and Ayyub, B. M., “Risk-based Simulation Models for the Construction of the Mobile Offshore Base,” Journal of Ship Production, SNAME, 16(4), 241-252.",
"Wilcox, R. C., Burrows, M. J., Ghosh, S., and Ayyub, B. M., “Risk-based Technology Methodology for the Safety Assessment of Marine Compressed Natural Gas Fuel Systems,” Marine Technology, Vol. 38:3, 2001, 193-207.",
"de Souza, G. F. M., and Ayyub, B. M., “Probabilistic Fatigue Life Prediction for Ship Structures Using Fracture Mechanics,” NEJ, ASNE, 112(4), July 2000, 375-397. Winner of the “Jimmie” Hamilton Best Paper Award, 2000, ASNE",
"Ayyub, B. M., Assakkaf, I., and Atua, K.I., “Reliability-Based Load and Resistance Factor Design (LRFD) of Hull Girders for Surface Ships,” Naval Engineers J., ASNE, 112(4), July 2000, 279-296.",
"Ziara, M., and Ayyub, B.M., “Decision Analysis for Housing-Project Development,“ J. of Urban Planning and Development, ASCE, 125(2), June 1999, 68-85. View",
"Inozu, B., and Ayyub, B.M., “Reliability, Maintenance and Risk Assessment in Naval Architecture and Marine Engineering Education in the US,“ European J. of Engineering Education, 24(3), 1999, 335-340. View",
"Ayyub, B. M., Karaszewski, Z., and Wade, M., “Probabilistic Risk Analysis of Diesel Power Generators Onboard Ships, “Naval Engineers J., ASNE, May 1999, 111(3), 35-58.",
"Atua, K.I., and Ayyub, B.M., “Reliability Analysis of Transverse Stability of Ships,” Naval Engineers J., ASNE, 109(3), May 1997.View",
"Ayyub, B.M., Kaminskiy, M.P., Patev, R.C., Leggett, M.A., “Loads for Fatigue Life Assessment of Gates at Navigation Locks,” J. of Infrastructure Systems, ASCE, 1997, 3(2), 68-77.View",
"Chao, R.-J., and Ayyub, B.M., “Reliability and Uncertainty Evaluation for Longitudinal Bending of Hull Girders of Surface Ships,” J. of Ship Research, SNAME, 4(1), March 1997, 57-68. View",
"Muhanna, R.L., Ayyub, B.M., and Bruckman, D.D., “Uncertainty Analysis of Structural Strength of Stiffened Panels,” Naval Engineers J., ASNE, 108(3), 1996, 399-418. View",
"Ayyub, B.M., Al-Mutairi, N., and Chang, P.C., “Structural Strength of Bridge Decks Reinforced With Welded Wire Fabric,” J. of Structural Engineering, ASCE, September 1996, 122(9), 989-997.",
"Johnson, P.A., and Ayyub, B.M., “Modeling Uncertainty in the Prediction of Pier Scour,” Journal of Hydraulic Engineering, ASCE, Feb. 1996, 122(2), 66-72. View",
"Chao, R.-J., and Ayyub, B.M., “Structural Analysis with Fuzzy Variables,” Microcomputers in Civil Engineering, 11(1), Jan. 1996, 47-58. View",
"Nayfeh, M.H., Habayeb, A.Z., and Ayyub, B.M. “GATT and Developing Countries,” The Arab Journal of Science, Arab League Educational, Scientific and Cultural Organization (ALESCO), No. 25, 1995, 33-41.",
"Ayyub, B.M., Habayeb, A.Z., and Nayfeh, M.H., “Trade-Related Aspects of Intellectual Property Right Including Trade in Counterfeit Goods,” The Arab Journal of Science, Arab League Educational, Scientific and Cultural Organization (ALESCO), No. 25, June 1995, 15-21.",
"Ayyub, B.M., “General Agreement of Tariffs and Trade (GATT),” The Arab Journal of Science, Arab League Educational, Scientific and Cultural Organization (ALESCO), No. 25, June 1995, 8-14.",
"Ayyub, B.M., Book Review of “Engineering Risk Analysis of Water Pollution, by J.G. Ganoulis, VCH Verlagsgesellschaft, Germany, 1994,” Water Resources Bulletin, the American Water Resources Association, 31(1), Feb. 1995, 161.",
"Ayyub, B.M., Beach, J., and Packard, T., “Methodology for the Development of Reliability-Based Design Criteria for Surface Ship Structures,” Naval Engineers Journal, ASNE, 107(1), Jan. 1995, 45-61.",
"Ayyub, B. M., Al-Mutairi, N.M., and Chang, P. C., “Splicing Strength of Welded Steel Mesh in Concrete Bridge Decks,” Journal of Structural Engineering, ASCE, 120(8), August 1994, 2532-2546.View",
"Ayyub, B. M., Al-Mutairi, N.M., and Chang, P. C., “Bond Strength of Welded Wire Fabric in Concrete Bridge Decks,” Journal of Structural Engineering, ASCE, 120(8), August 1994, 2520-2531.View",
"Lai, K.-L., and Ayyub, B.M., “Generalized Uncertainty in Structural Reliability Assessment,” Civil Engineering Systems, 11(2), 1994, 81-110. View",
"Ayyub, B. M., Chang, P. C., and Al-Mutairi, N. M., “Welded Wire Fabric for Bridges. II: Fatigue Strength,” Journal of Structural Engineering, ASCE, 120(6), June 1994, 1882-1892.",
"Ayyub, B. M., and Chang, P. C., and Al-Mutairi, N. M., “Welded Wire Fabric for Bridges. I: Ultimate Strength and Ductility,” Journal of Structural Engineering, ASCE, 120(6), June 1994, 1866-1881.",
"Hassan, M.H.M., and Ayyub, B. M., “A Fuzzy Controller for Construction Activities,” Fuzzy Sets and Systems, North-Holland, 56(3), 1993, 253-271. View",
"Hassan, M.H.M., and Ayyub, B.M. “Multi-Attribute Control of Construction Activities,” Civil Engineering Systems, 10, 1993, 37-53. View",
"Alvi, I.A., and Ayyub, B.M., “Generalized Reliability Assessment and Reliability-Based Design for Structural Safety and Serviceability,” Design for Safety and Serviceability, ACI Publication No. SP-133, Edited by Edward G. Nawy and Andrew Scanlon, 1992, 133-158.",
"Ayyub, B.M., and Hassan, M.H.M., “Control of Construction Activities: III. A Fuzzy-Based Controller,” Civil Engineering Systems, 9, 1992, 275-297.",
"Ayyub, B.M., and Hassan, M.H.M., “Control of Construction Activities: II. Condition Assessment of Attributes,” Civil Engineering Systems, 9, 1992, 147-204.",
"Ayyub, B.M., and Hassan, M.H.M., “Control of Construction Activities: I. Systems Identification,” Civil Engineering Systems, 9, 1992, 123-146.",
"Ayyub, B.M., Sohn, Y.G. and Saadatmanesh, H., “Prestressed Composite Girders: Experimental Study for Negative Moment,” Journal of Structural Engineering, ASCE, October 1992, 118(10), 2763-2783.View",
"Ayyub, B.M., Sohn, Y.G. and Saadatmanesh, H., “Prestressed Composite Girders: Analytical Study for Negative Moment,” Journal of Structural Engineering, ASCE, October 1992, 118(10), 2743-2762. View",
"Ayyub, B.M., Book Review of “Dynamics of Fixed Marine Structures, third edition, by N.D.P. Barltrop, et al, Butterworth-Heinemann, ltd., and Marine Technology Directorate, ltd., 1991,” Naval Engineers Journal, ASNE, 104(4), July 1992, 130-131.",
"Fu, C.C., Schelling, D.R., and Ayyub, B.M., “Evaluation of Truck Configurations Based on Structural Performance of Bridges,” Journal of Advanced Transportation, Advanced Transit Association, 26(3), 1992, 229-324.",
"Johnson, P.A., and Ayyub, B.M., “Assessment of Time-Variant Bridge Reliability Due to Pier Scour,” J. of Hydraulic Engineering, ASCE, 118(6), June 1992, 887-903. View",
"Ayyub, B.M., and Lai, K.-L., “Structural Reliability Assessment with Ambiguity and Vagueness in Failure, reply by the authors on comments by reviewers,” NEJ, ASNE, 104(4), July 1992, 82-84.",
"Ayyub, B.M., and Lai, K.-L., “Structural Reliability Assessment with Ambiguity and Vagueness in Failure,” Naval Engineers Journal, ASNE, 104(3), May 1992, 21-35, Winner of the “Jimmie” Hamilton Award, 1992, ASNE.",
"Ayyub, B.M., Book Review of “Handbook of Applied Mathematics for Engineers and Scientists, by Max Kurtz, McGraw Hill, 1991,” NEJ, ASNE, 104(2), March 1992, 94-95.",
"Ayyub, B.M., and Chia, C.-Y., “Generalized Conditional Expectation for Structural Reliability Assessment,” Structural Safety, 11(2), 1992, 131-146.",
"Ibrahim, A., and Ayyub, B.M., “Multi-Criteria Ranking of Components According to their Priority for Inspection,” Fuzzy Sets and Systems, North-Holland, 48(1), 1992, 1-14. View",
"Ayyub, B.M., Schelling, D.R., Fu, C.C., and Hassan, M.H.M., “Bridge Sampling for Evaluating Structural Impact of Trucks,” Journal of Advanced Transportation, Advanced Transit Association, 26(1), 1992, 79-102.",
"Eldukair, Z. A., and Ayyub, B. M., “Multi-Attribute Fuzzy Decisions in Construction Strategies,” Fuzzy Sets and Systems, North-Holland, 40(9), 1991, 890-912.",
"Saadatmanesh, H., Albrecht, P., and Ayyub, B.M., “Guidelines for Flexural Design of Prestressed Composite Beams,” Closure by Authors on Comments by P. Kocsis, Journal of Structural Engineering, ASCE, 117(11), November 1991, 3548-3549.",
"Ayyub, B.M., “Systems Framework for Fuzzy Sets in Civil Engineering,” international journal of Fuzzy Sets and Systems, North-Holland, Amsterdam, 40(3), 1991, 491-508.",
"Ayyub, B.M., and Lai, K.-L., “Selective Sampling in Simulation-Based Reliability Assessment,” International Journal of Pressure Vessel and Piping, 46(2), 1991, 229-249.",
"Eldukair, Z. A., and Ayyub, B. M., “Analysis of Recent U.S. Structural and Construction Failures,” Journal of Performance of Constructed Facilities, ASCE, 5(1), February 1991, 57-73. View",
"Ayyub, B.M., Sohn, Y.G. and Saadatmanesh, H., “Prestressed Composite Girders under Positive Moment,” Journal of Structural Engineering, ASCE, 116(11), November 1990, 2931-2951. View",
"McCuen, R.H., Ayyub, B.M., and Hromadka, T.V., “Risk of Debris Basin Failure,” Journal of Water Resources Planning and Management, ASCE, 116(4), July/August 1990, 473-483. View",
"Ayyub, B.M., and Ibrahim, A., “Post-tensioned Trusses: Redundancy and Reliability,” Journal of Structural Engineering, ASCE, 116(6), June 1990, 1507-1521.",
"Ayyub, B.M., Ibrahim, A., and Schelling, D., “Post-tensioned Trusses: Analysis and Design,” Journal of Structural Engineering, ASCE, 116(6), June 1990, 1491-1506. View",
"Ayyub, B. M., White, G. J., Bell-Wright, T. F., and Purcell, E. S., “Comparative Structural Life Assessment of Patrol Boat Bottom Plating, reply by the authors on comments by R.A. Sielski, A. Haldar and E.T. Reilly,” Naval Engineers Journal, ASNE, 102(4), July 1990, 150-153",
"Ayyub, B. M., White, G. J., Bell-Wright, T. F., and Purcell, E. S., “Comparative Structural Life Assessment of Patrol Boat Bottom Plating,” NEJ, ASNE, 102(3), May 1990, 253-262.",
"Ayyub, B. M., and White, G. J., “Structural Life Expectancy of Marine Vessels,” Marine Structures: Design, Construction and Safety, published by Elsevier Applied Science and International Ship & Offshore Structures Congress, 3(4), April 1990, 301-317.",
"Eldukair, Z. A., and Ayyub, B. M., “Project Correlation in Portfolio Theory,” Civil Engineering Systems, 7(3), 1990, 170-177. View",
"Ayyub, B. M., White, G. J., and Bell-Wright, T. F., “Life Expectancy Assessment of Structural Systems,” proceedings of the third WG 7.5 working conference on reliability and optimization of structural systems, International Federation for Information Processing, edited by A. Der Kiureghian and P. Thoft-Christensen, Springer-Verlag, March 1990, 33-42.",
"Balkey, K., Ayyub, B.M., Gore, B., Simonen, F., Harris, D., Smith, H., Karydas, D., “Risk-Based Inspection Guidelines – A Quantified Process to Supplement Engineering Judgment,” Mechanical Engineering, ASME, March 1990, 68-74.View",
"Ayyub, B.M., and McCuen, R.H., “Optimum Sampling for Structural Strength Evaluation,” Journal of Structural Engineering, ASCE, 116(2), February 1990, 540-550.",
"Ayyub, B.M., and McCuen, R.H., “Defining Stream Benefits: System and Uncertainty Modeling,” Technote Discussion, Hydata News and Views, American Water Resources Association, 9(1), January 1990, 19-20.",
"Saadatmanesh, H., Albrecht, P., and Ayyub, B.M., “Guidelines for Flexural Design of Prestressed Composite Beams,” Journal of Structural Engineering, ASCE, 115(12), December 1989, 2944-2961.",
"Ayyub, B.M., White, G.J., and Purcell, E.S., “Estimation of the Structural Service Life of Boats, reply by the authors on comments by A. Haldar, A. Malakoff and J.S. Spencer,” NEJ, ASNE, 101(4), July 1989, 132-134.",
"Saadatmanesh, H., Albrecht, P., and Ayyub, B.M., “Analytical Study of Prestressed Composite Beams,” Journal of Structural Engineering, ASCE, 115(9), September 1989, 2364-2381.",
"Saadatmanesh, H., Albrecht, P., and Ayyub, B.M., “Experimental Study of Prestressed Composite Beams,” Journal of Structural Engineering, ASCE, 115(9), September 1989, 2348-2363. View",
"Ayyub, B.M., White, G.J., and Purcell, E.S., “Estimation of the Structural Service Life of Boats,” Naval Engineers Journal, ASNE, 101(3), May 1989, 156-166.View",
"Ayyub, B. M., Kadissi, R. I., “Failure and Survival Functions for Structural Design Codes,” International Journal of Structures, Paper No. 92, 9(1), January 1989, 1-23.",
"Ayyub, B.M., “RASCS for Risk Assessment,” Technical Note, International Journal of Software for Engineering Workstations, 5(1), January 1989, 45-50.",
"McCuen, R. H., Aggour, M.S., and Ayyub, B.M., “Spacing for Accuracy in Ultrasonic Testing for Bridge Timber Piles,” Journal of Structural Engineering, ASCE, 114(12), December 1988, 2652-2668. View",
"Ayyub, B.M., and Eldukair, Z., “Discussion on A Fuzzy System for Bid Proposal Evaluation Using Microcomputers by C.H. Juang, J.L. Burati and S.N. Kalidindi,” Civil Engineering Systems, 5(3), September 1988, 164.",
"Ayyub, B.M., “Discussion on Limit State Probabilities for Wood Structural Members, by E. Hendrickson, B. Ellingwood and J. Murphy,” Journal of Structural Engineering, ASCE, 114(5), May 1988, 1196-1200.",
"Chang, P.C., and Ayyub, B.M., “The Influence of Shear Lag on the Vibration of Timoshenko Beam,” International Journal of Structures, 8(1), January 1988, 1-14.",
"Chang, P.C., Ayyub, B.M., and Al-Mutairi, N., “Moment-Rotation Characteristic of Slabs,” International Journal of Software for Engineering Workstations, 3(3), July 1987, 124-128. View",
"White, G.J., and Ayyub, B.M., “Reliability-Based Fatigue Design for Ship Structures, reply by the authors on comments by R. Sielski, P.Y. Chang and A. Thayamballi,” NEJ, ASNE, 99(4), July 1987, 128-130. View",
"White, G.J., and Ayyub, B.M., “Reliability-Based Fatigue Design for Ship Structures,” Naval Engineers Journal, ASNE, 99(3), May 1987, 135-149.",
"White, G.J., and Ayyub, B.M., “Reliability-Based Design Formats for Marine Structures,” Journal of Ship Research, SNAME, 31(1), March 1987, 60-69.",
"Ayyub, B.M., and White, G.J., “Reliability-Conditioned Partial Safety Factors,” Journal of Structural Engineering, ASCE, 113(2), February 1987, 279-294.",
"Ayyub, B.M., Aggour, M.S., and McCuen, R.H., “Semivariogram Algorithm for Timber Pile Data Analysis,” International Journal of Software for Engineering Workstations, 3(1), January 1987, 44-47.",
"Ayyub, B.M., and McCuen, R.H., “Quality and Uncertainty Assessment of Wildlife Habitat Based on Fuzzy Sets,” Journal of Water Resources Planning and Management, ASCE, 113(1), January 1987, 95-109. Winner of the Outstanding Research Oriented Paper Award, 1988, WRPMD, ASCE",
"Ayyub, B.M., and Haldar, A., “Decisions in Construction Operations,” Journal of Construction Engineering and Management, ASCE, 111(4), December 1985, 343-357.",
"White, G.J., and Ayyub, B.M., “Reliability Methods for Ship Structures, reply by the authors on comments by A. Haldar, A.E. Mansour and A. Sielski,” Naval Engineers Journal, ASNE, 97(4), July 1985, 79-82.",
"White, G.J., and Ayyub, B.M., “Reliability Methods for Ship Structures,” Naval Engineers Journal, ASNE, 97(4), May 1985, 86-96. Winner of the “Jimmie” Hamilton Best Paper Award, 1985, ASNE",
"Ayyub, B.M., and Haldar, A., “Practical Structural Reliability Techniques,” Journal of Structural Engineering, ASCE, 110(8), August 1984, 1707-1724.",
"Haldar, A., and Ayyub, B.M., “Practical Variance Reduction Techniques in Simulation,” Publication PVP-Vol. 93, ASME, June 1984, 63-74.",
"Ayyub, B.M. and Haldar, A., “Project Scheduling Using Fuzzy Set Concepts,” Journal of Construction Engineering and Management, ASCE, 110(2), June 1984, 189-204."];
export default archivedPaperList;