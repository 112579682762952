import { Typography } from "@mui/material"
import React from "react";

export default function RFinkensteinPage() {
  return <React.Fragment>
    <Typography variant='body1' component='p' py={1}>Dr. Robert Finkelstein earned a B.A. in Physics from Temple University in 1964, an M.S. in Physics from the University of Massachusetts in 1966, an M.S. in Operations Research from the George Washington University (GWU) in 1974, an Ap.Sci. (Applied Scientist degree) in Operations Research from GWU in 1977, a Doctorate with the primary field of Systems Theory and Cybernetics and the supporting field of the Management of Science, Technology, and Innovation from GWU in 1995, as well as an LL.B. in American law and Procedure from LaSalle Extension University in 1971, Diplomas from the U.S. Army Missile School in 1967 and the U.S. Army Ordnance School in 1966, Certificates from the University of Tennessee Space Institute in Combat Obscuration Modeling in 1978 and the University of California, Los Angeles, in Battlefield Robotics in 1983, and post-graduate courses in Physics at the Massachusetts Institute of Technology during 1968-1970.</Typography>
    <Typography variant='body1' component='p' py={1}>As President of Robotic Technology Inc. (RTI) from 1985 to the Present, Dr. Finkelstein is responsible for the development of autonomous intelligent robots and intelligent systems, technical analyses, technology assessments, operations research, business development, and other professional services, for government, industry, and academia – nationally and internationally – in military and civil advanced technology systems, especially robotics, unmanned vehicles, and intelligent systems. In recent years he invented the Energetically Autonomous Tactical Robot (EATR), developed under sponsorship of the Defense Advanced Research Projects Agency (DARPA); he created and managed the Intelligent Vehicle Technology Transfer (IVTT) Program under sponsorship of DARPA; and he initiated the Military Memetics Program, under sponsorship of DARPA.</Typography>
    <Typography variant='body1' component='p' py={1}>Dr. Finkelstein also serves as adjunct Professor at the University of Maryland University College in the Graduate School of Management and Technology, from 1999 to the Present, teaching graduate courses in: systems engineering; information technology; foundations of management and technology; strategic management of technology and innovation; systems and operations research; the manager in a technological society; principles of technology management; risk management; contract pricing and negotiation; and project management. He is an adjunct Professor for Unmanned Vehicle University. Since 2008 he has served as a Director of the Intelligent Systems Laboratory in the Center for Technology and Systems Management within the Clark School of Engineering at the University of Maryland, College Park.</Typography>
    <Typography variant='body1' component='p' py={1}>Previously, as a Systems Scientist with the MITRE Corporation, he was responsible for studies involving C3I, weapons systems analysis, robotics, and artificial intelligence. As a Member of the Technical Staff at the Massachusetts Institute of Technology he served as a physicist and operational analyst for the Apollo Manned Spaceflight Program, receiving the MIT Certificate of Commendation. As an Anti-Satellite Systems Officer with the U.S. Missile Intelligence Agency during the Vietnam War he was awarded the Army Commendation Medal and National Defense Service Medal.</Typography>
    <Typography variant='body1' component='p' py={1}>Dr. Finkelstein has authored more than a 200 technical reports and studies. He contributed articles to publications such as National Defense, Sea Power, and the Armed Forces Journal. Wrote a quarterly column and served on the Editorial Board for the journal Unmanned Systems, and served on the Editorial Board of the journal Mathematical Machines and Systems. His books include Unmanned Vehicle Systems: Military and Civil Robots for the 21st Century & Beyond, Pasha Publications in 1994, and Defense Year-Book 1992 (“Combat Robotics: From the Kaiser to the New World Order”), Brassey’s Publications. He has been featured in the print media, including: New York Times, Washington Post, Wall Street Journal, Baltimore Sun, Philadelphia Inquirer, Boston Globe, Los Angeles Times, Palm Beach Post, Arizona Daily Star, Montgomery County Gazette, London Guardian, Business Week, High Technology, Omni, Fortune, Defense News, Unmanned Systems, Popular Science, Scientific American, among other publications, including books and many online publications. He was featured on radio and television, including: PBS radio, BBC radio, CBS news, other local and national radio stations, the History TV channel, ITV news in London, and MSNBC.</Typography>
    <Typography variant='body1' component='p' py={1}>He has been a member of several Professional Societies, including: Sigma Pi Sigma (Physics Honor Society), Omega Rho (Operations Research Honor Society), Beta Gamma Sigma (Business Honor Society), Institute For Operations Research and the Management Sciences, Washington Institute For Operations Research and Management Science, Military Operations Research Society, American Association for the Advancement of Science, American Physical Society, American Anthropological Association, American Association for Artificial Intelligence, American Society for Cybernetics, Institute of Electrical and Electronics Engineers, Association for Unmanned Vehicles Systems International, Intelligent Transportation Society of America, National Defense Industrial Association, World Future Society, Washington Academy of Science, and Association for Intelligence Officers.</Typography>
  </React.Fragment>;
}